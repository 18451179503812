<template>
  <div>
    <h4 v-show="!disable">POR PAGAR</h4>
    <app-table-registers
      :exeGetChildAfterMount="!disable"
      ref="tr"
      :getList="getJobs"
    >
      <template slot="tl">
        <div class="col">
          <div v-show="!disable">
            <button
              class="btn btn-success mr-1"
              @click="$refs.elModalLestPay.show()"
            >
              Pagar Marcados
            </button>

            <button class="btn btn-secondary" @click="checkAll()">
              Marcar todos
            </button>
          </div>
        </div>
      </template>
      <template slot="table">
        <thead>
          <tr>
            <th></th>
            <th>TRATAMIENTO</th>
            <th>PACIENTE</th>
            <th>PIEZA</th>
            <th>DOCTOR</th>
            <th>MONTO</th>
            <th>ESTADO</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="l in list.data" :key="l.id">
            <td class="text-center">
              <div v-show="!disable">
                <app-checkbox
                  v-show="l.status == 6 && l.maked"
                  v-model="l.to_pay"
                  placeholder=""
                ></app-checkbox>
              </div>
            </td>
            <td>
              <router-link
                :to="
                  '/dental-clinic/patients/' +
                    l.client_id +
                    '/treatment-plans/' +
                    l.treatment_plan_id
                "
                class="text-decoration-none text-dark"
                >#{{ l.treatment_plan_id }}
                {{ l.product_name }}
              </router-link>
            </td>
            <td>{{ l.client_name }}</td>
            <td>
              <ToothPiece
                v-for="(l1, ind) in l.th_pieces"
                :key="ind"
                :piece="l1"
              ></ToothPiece>
            </td>
            <td>
              {{ l.employee_name }}
            </td>
            <td class="text-right">
              <app-span-money
                :quantity="l.cost"
                :moneyCode="1"
              ></app-span-money>
            </td>
            <td>
              <div>{{ supplierState[l.status] }}</div>
              <small class="text-danger text-nowrap" v-show="!l.maked">
                No evolucionado
              </small>
            </td>
          </tr>
          <tr>
            <td></td>
            <th class="text-right" colspan="4">TOTAL</th>
            <th class="text-right">
              <app-span-money :quantity="total" :moneyCode="1"></app-span-money>
            </th>
            <td></td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="elModalLestPay">
      <div class="form-group">
        <label for="">Monto</label>
        <app-input-money
          :disabled="true"
          :quantity="toPay"
          :moneyCode="1"
        ></app-input-money>
      </div>
      <app-button-submit @click="goToPay()" text="Pagar"></app-button-submit>
    </app-modal-basic>
  </div>
</template>
<script>
import { DentalClinicService } from "../service";
import ToothPiece from "../odontogram/ToothPiece";
import { mapState } from "vuex";
// import letsPay from "./lets-pat";

export default {
  components: {
    ToothPiece,
    // letsPay
  },

  // directives
  // filters

  props: {
    disable: {
      default: false
    },
    supplier_id: {},
    payment_id: {},
    paid_out: {
      default: 0
    }
  },

  data: () => ({
    list: {},
    to_pay_method: 1
  }),

  computed: {
    ...mapState({
      supplierState: s => s.config.logistic.supplier_jobs.states
    }),
    total() {
      return (
        this.list.data &&
        (this.list.data
          .map(x => parseFloat(x.cost))
          .reduce((a, b) => a + b, 0) ||
          0)
      );
    },
    toPay() {
      return (
        this.list.data &&
        (this.list.data
          .filter(x => x.to_pay)
          .map(x => parseFloat(x.cost))
          .reduce((a, b) => a + b, 0) ||
          0)
      );
    }
  },

  watch: {
    payment_id() {
      this.$refs.tr.gl();
    }
  },

  mounted() {
    this.$emit("mounted");
  },

  methods: {
    checkAll() {
      this.list = {
        ...this.list,
        data: this.list.data.map(l => {
          if (l.status == 6) l.to_pay = true;
          return l;
        })
      };
      // for (let l of this.list.data) {

      //   if (l.status == 6) l.to_pay = true;
      // }
      // this.$forceUpdate();
    },
    goToPay() {
      DentalClinicService.payLaboratoryJobs({
        supplier_job_ids: this.list.data.filter(x => x.to_pay).map(x => x.id),
        supplier_id: this.supplier_id
      }).then(() => {
        this.$refs.tr.gl();
        this.$refs.elModalLestPay.hide();
        this.$emit("paid");
      });
    },
    getJobs(params) {
      return new Promise(rsv => {
        DentalClinicService.getLaboratoryJobs({
          supplier_id: this.supplier_id,
          paid_out: this.paid_out,
          ...params,
          payment_id: this.payment_id
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
