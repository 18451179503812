<template>
  <form @submit.prevent="save()">
    <div class="form-group">
      <label for>Proveedor</label>
      <SelectSupplier ref="selSup" v-model="reg.supplier_id"></SelectSupplier>
    </div>
    <div class="form-group">
      <label for>Producto</label>
      <SelectProduct ref="selPro" v-model="reg.product_id"></SelectProduct>
    </div>
    <div class="form-group">
      <label for>Precio</label>
      <app-input-money
        ref="inpMon"
        :moneyCode.sync="reg.money_code"
        :quantity.sync="reg.price"
      ></app-input-money>
    </div>
    <div class="form-group">
      <app-checkbox>Cobrar al doctor</app-checkbox>
    </div>
    <div class="form-group">
      <label for>Categoria</label>
      <SelectCategorie
        ref="selCat"
        :disabled="!!categorie_id"
        v-model="reg.categorie_id"
      ></SelectCategorie>
    </div>
    <div class="form-group">
      <app-button-submit></app-button-submit>
    </div>
  </form>
</template>

<script>
import SelectSupplier from "../suppliers/Select";
import SelectProduct from "../../store-module/products/Select";
import { LogisticService } from "../service";
import SelectCategorie from "../../general-module/categories/Select";

export default {
  components: {
    SelectSupplier,
    SelectProduct,
    SelectCategorie
  },
  data: () => ({
    reg: {}
  }),
  props: {
    categorie_id: {
      default: undefined
    },
    supplier_id: {
      default: undefined
    }
  },
  methods: {
    save() {
      // console.log('q fue perro');
      LogisticService.saveSupplierProduct(this.reg).then(res => {
        this.$emit("submitted", res);
      });
    },
    loadReg(reg) {
      this.reset();
      this.reg = reg;
      this.$refs.selSup.setValueFromId(reg.supplier_id);
      this.$refs.selPro.setValueFromId(reg.product_id);
      this.$refs.selCat.setValueFromId(reg.categorie_id);
    },
    reset() {
      this.reg = {};
      this.$refs.inpMon.setDefault();
      this.$refs.selSup.reset();
      this.$refs.selSup.setValueFromId(this.supplier_id);
      this.$refs.selPro.reset();
      this.$refs.selCat.setValueFromId(this.categorie_id);
    }
  }
};
</script>

<style>
</style>
