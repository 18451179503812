<template>
  <div>
    <div v-show="!$route.query.supplier_payment_id">
      <h4>PAGOS REALIZADOS</h4>
      <app-table-registers ref="tr" :getList="getList">
        <template slot="top">
          <div class="col"></div>
        </template>
        <template slot="table">
          <tr v-for="l in list.data" :key="l.id">
            <td>{{ l.id }}</td>
            <td>{{ l.created_at | dateFormat }}</td>
            <td>{{ l.concept }}</td>
            <td>
              <app-span-money
                :quantity="l.amount"
                :moneyCode="l.money_code"
              ></app-span-money>
            </td>
            <td>
              <!-- <a
              href="javascript:void(0)"
              @click="
                $refs.elModalDetail.show();
                payment_id = l.id;
              "
            >
              detalles
            </a> -->

              <router-link
                :to="{
                  query: {
                    supplier_payment_id: l.id,
                    ...$route.query
                  }
                }"
              >
                <i class="fa fa-search-plus"></i> detalles
              </router-link>
            </td>
          </tr>
        </template>
      </app-table-registers>
    </div>

    <to-pay
      v-show="$route.query.supplier_payment_id"
      ref="toPay"
      :paid_out="1"
      :payment_id="payment_id"
      :disable="true"
      @mounted="payment_id = $route.query.supplier_payment_id"
    ></to-pay>
  </div>
</template>
<script>
import { LogisticService } from "../../logistic-module/service";
import toPay from "./to-pay";

export default {
  components: {
    toPay
  },

  // directives
  // filters

  props: {
    supplier_id: {}
  },

  data: () => ({
    list: {},
    payment_id: null
  }),

  computed: {
    //
  },

  watch: {
    $route(to) {
      if (to.query.supplier_payment_id) {
        this.payment_id = to.query.supplier_payment_id;
      }
    }
  },

  mounted() {
    // this.payment_id = to.query.supplier_payment_id;
  },

  methods: {
    reload() {
      this.$refs.tr.gl();
    },
    getList(params) {
      return new Promise(rsv => {
        LogisticService.getSupplierPayments({
          ...params,
          supplier_id: this.supplier_id
        }).then(data => {
          this.list = data;
          rsv(data);
        });
      });
    }
  }
};
</script>

<style></style>
