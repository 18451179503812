<template>
  <div>
    <h4>Prestaciones abastecidas por este laboratorio</h4>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.dForm.show();
        $refs.form.reset();
      "
    >
      <!-- <div class="row mb-2">
        <div class="col-md-4">
          <SelectSupplier ref="selSup" v-model="supplier_id"></SelectSupplier>
        </div>
      </div> -->
      <!-- <template slot="tl">
        <div class="col"></div>
      </template> -->
      <template slot="table">
        <thead>
          <tr>
            <!-- <th>ID</th> -->
            <th>Producto</th>
            <!-- <th>Proveedor</th> -->
            <th>Precio</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <!-- <td>{{ l.id }}</td> -->
            <td :title="l.product_id">
              {{ l.product_name }}
            </td>
            <!-- <td>{{ l.supplier_name }}</td> -->
            <td>
              <app-span-money
                :quantity="l.price"
                :moneyCode="l.money_code"
              ></app-span-money>
            </td>
            <td>
              <button
                class="btn btn-light btn-sm"
                @click="
                  $refs.form.loadReg(l);
                  $refs.dForm.show();
                "
              >
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dForm">
      <Form
        :categorie_id="config.lab_job_categorie_id"
        ref="form"
        :supplier_id="supplier_id"
        @submitted="
          $refs.tr.gl();
          $refs.dForm.hide();
        "
      ></Form>
    </app-modal-basic>
  </div>
</template>

<script>
// import { DentalClinicService } from "../service";
// import SelectSupplier from "../../logistic-module/suppliers/Select";
import Form from "../../logistic-module/supplier-products/Form";
import { mapState } from "vuex";
import { LogisticService } from "../../logistic-module/service";

export default {
  components: {
    // SelectSupplier,
    Form
  },
  props: {
    supplier_id: { required: true }
  },
  data: () => ({
    list: {}
    // supplier_id: undefined
  }),
  computed: {
    ...mapState({
      config: s => s.config.dental.laboratory
    })
  },
  watch: {
    supplier_id() {
      this.$refs.tr.gl();
    }
  },
  created() {},
  mounted() {},
  methods: {
    getList({ page, search }) {
      return new Promise(rsv => {
        LogisticService.getSupplierProducts({
          page,
          search,
          supplier_id: this.supplier_id
        }).then(res => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
